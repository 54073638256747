import type { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { icon, library } from '@fortawesome/fontawesome-svg-core';

library.add(fab, fas, far);

type Props = {
  icon: IconName;
  prefix?: IconPrefix;
  spin?: boolean;
  className?: string;
};

export const Icon = ({ icon: iconName, prefix = 'fas', spin = false, className }: Props) => {
  const classes = className ? [className] : [];

  if (spin) {
    classes.push('animate-spin');
  }

  const iconHTML = icon(
    {
      prefix,
      iconName,
    },
    {
      classes,
    },
  )?.html;

  return <span className="flex items-center" dangerouslySetInnerHTML={{ __html: iconHTML?.[0] }} />;
};
